import axios from "axios";

const baseDomain = "https://api-m.paypal.com/v1";
const baseURL = `${baseDomain}/`;
let axiosObj;
axiosObj = axios.create({
  baseURL,
  headers: {
    Authorization: ``,
  },
});
export default axiosObj;
