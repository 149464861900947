import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Spinner,
} from "reactstrap";
import { canncelSubscription } from "store/actions/subscriptions";

import SubscribedInfoModal from "./modals/SubscribedInfoModal";
import { getMode } from "../store/actions/subscriptions";

const SubscribedPackages = ({ item, index }) => {
  const dispatch = useDispatch();

  let { cancelSubscriptionLoader, currentUserSubscriptions ,mode} = useSelector(
    (state) => state.subscriptions
  );
  const { uid } = useSelector((state) => state.authUser);
  
  // console.log(currentUserSubscriptions,"subscriptions")
  const [activeButton, setActiveButton] = useState("");

  const [subscribedModal, setSubscribedModal] = useState(false);

  const toggleSubscribedModal = () => setSubscribedModal(!subscribedModal);
  // console.log(item,"<--items")
  useEffect(()=>{
    dispatch(getMode())
  },[])
  return (
    <div>
      <SubscribedInfoModal
        subscribedModal={subscribedModal}
        toggleSubscribedModal={toggleSubscribedModal}
        item={item}
        subscriptedPackage={currentUserSubscriptions?.find(
          (subscribe) => subscribe.packageId == item.id
        )}
      />
      <Card className="mt-3">
        <CardImg
          top
          className="w-100 h-auto "
          style={{ aspectRatio: "3/2" }}
          src={item?.packageImageURL}
          alt="Card image cap"
        />
        <CardBody>
          <div className="d-flex justify-content-between align-items-center">
            <CardTitle className="m-0 p-0">{item?.packageName}</CardTitle>
            <Button
              size="sm"
              onClick={() => {
                toggleSubscribedModal();
              }}
            >
              info
              <i className="fa fa-info mx-2"></i>
            </Button>
          </div>
          <CardSubtitle className="my-3">${item?.packagePrice}</CardSubtitle>
          <Button
            size="sm"
            color="danger"
            disabled={cancelSubscriptionLoader}
            onClick={(event) => {
              // event.preventDefault();
              dispatch(canncelSubscription(mode[0].type,item?.id, uid));
              setActiveButton(item?.id);
            }}
          >
            {activeButton === item?.id && cancelSubscriptionLoader ? (
              <Spinner size={"sm"} />
            ) : (
              "UnSubscribe"
            )}
            {/* Unsubscribe */}
          </Button>
        </CardBody>
      </Card>
    </div>
  );
};

export default SubscribedPackages;
