import { LOGIN, LOGOUT } from "../types";
import firebase from "../../config/firebase";
import { uuid as uuidv4 } from "uuidv4";

export const SignIn =
  (email, password, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({ type: "LOGIN_LOADER", payload: true });
      await firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          var user = userCredential.user.uid;
          firebase
            .firestore()
            .collection("users")
            .doc(user)
            .onSnapshot(async (query) => {
              if (query.data()) {
                dispatch({
                  type: LOGIN,
                  payload: { uid: user, user: { id: user, ...query.data() } },
                });
              }
            });
          onSuccess();
          dispatch({ type: "LOGIN_LOADER", payload: false });
        })
        .catch((err) => {
          console.log(err.message);
          alert(err.message);
          dispatch({ type: "LOGIN_LOADER", payload: false });
        });
    } catch (err) {
      console.log(err.message);
      alert(err.message);
      dispatch({ type: "LOGIN_LOADER", payload: false });
    }
  };

export const SignUp =
  (payload, password, history, onSuccess = () => {}) =>
  async (dispatch) => {
    const provider = new firebase.auth.GoogleAuthProvider();
    dispatch({ type: "REGISTER_LOADER", payload: true });

    firebase
      .auth()
      .createUserWithEmailAndPassword(payload.email, password)
      .then((data) => {
        firebase
          .firestore()
          .collection("users")
          .doc(data.user.uid)
          .set({
            ...payload,
            createdAt: firebase.firestore.Timestamp.now(),
            dob: "",
            gender: "",
            phone: "",
            platform: "email",
            profilePictureURL: "",
            role: "customer",
            // totalSpent: totalSpent,
            totalSpent: 0,
          })
          .then((res) => {
            // HigherOrderSignupFunc(data);
            dispatch({ type: "REGISTER_LOADER", payload: false });
            onSuccess("pass");
          })
          .catch((err) => {
            dispatch({ type: "REGISTER_LOADER", payload: false });
            alert(err, "danger");
          });
      })
      .catch((err) => {
        dispatch({ type: "REGISTER_LOADER", payload: false });

        alert(err.message);
      });
  };

export const LogOut =
  (onSuccess = () => {}) =>
  async (dispatch) => {
    await firebase
      .auth()
      .signOut()
      .then((data) => {
        localStorage.clear();
        window.location.href = "/auth/login";
        // onSuccess();
      })
      .catch((error) => {});
  };
export const resetPassword =
  (email, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch({ type: "RESET_LOADER", payload: true });
    await firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        onSuccess();
        dispatch({ type: "RESET_LOADER", payload: false });
      })
      .catch((error) => {
        console.error(error);
        dispatch({ type: "RESET_LOADER", payload: false });
      });
  };

// action for updating name
export const handleNameUpdate =
  (id, updatedName, onSuccess = () => {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: "NAME_LOADER",
      payload: true,
    });
    firebase
      .firestore()
      .collection("users")
      .doc(id)
      .update({
        firstName: updatedName,
      })
      .then(() => {
        // Get the existing user object from the Redux store
        const { user } = getState();

        // Create a new user object with the updated name
        const updatedUser = {
          ...user,
          firstName: updatedName,
        };

        // Dispatch an action to update the user in the Redux store
        dispatch({ type: "UPDATE_USER", payload: updatedUser });

        onSuccess();
        dispatch({
          type: "NAME_LOADER",
          payload: false,
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: "NAME_LOADER",
          payload: false,
        });
      });
  };

// password change action

export const handlePasswordChangeAction =
  (
    userId,
    oldPassword,
    newPassword,
    confirmPassword,
    onSuccess = () => {},
    onError = () => {}
  ) =>
  async (dispatch) => {
    dispatch({
      type: "PASSWORD_LOADER",
      payload: true,
    });

    try {
      const userRef = firebase.firestore().collection("users").doc(userId);
      const userDoc = await userRef.get();

      if (userDoc.exists) {
        const user = await firebase.auth().currentUser;
        const credential = firebase.auth.EmailAuthProvider.credential(
          user.email,
          oldPassword
        );
        // console.log(user, "user");
        // Reauthenticate user before changing password
        user
          .reauthenticateWithCredential(credential)
          .then(() => {
            user
              .updatePassword(newPassword)
              .then(() => {
                onSuccess();
                dispatch({
                  type: "PASSWORD_LOADER",
                  payload: false,
                });
                dispatch({
                  type: LOGIN,
                });
              })
              .catch((error) => {
                // onError(error.message);
                dispatch({
                  type: "PASSWORD_LOADER",
                  payload: false,
                });
              });
          })
          .catch((error) => {
            onError(error.message);
            dispatch({
              type: "PASSWORD_LOADER",
              payload: false,
            });
          });
      } else {
        console.log(`User with ID ${userId} does not exist.`);
      }
    } catch (error) {
      alert(error.message);
    }
  };

export const updateProfilePicture =
  (userId, profilePictureFile, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch({
      type: "UPDATE_PROFILE",
      payload: true,
    });

    let userDetails = await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .get();

    // console.log("useDetails", userDetails);
    if (userDetails.data()) {
      let { profilePictureURL } = userDetails.data();
      // console.log("profilePictureURL", profilePictureURL);

      if (profilePictureURL && profilePictureURL != "") {
        firebase.storage().refFromURL(profilePictureURL).delete();
      }
      let fileName = profilePictureFile?.name;
      let fileName1 = fileName.slice(fileName.lastIndexOf("."));
      let fileName2 = uuidv4() + fileName1.toLowerCase();

      // console.log("filename2", fileName2);
      let storageRef = await firebase
        .storage()
        .ref(fileName2)
        .put(profilePictureFile);
      let url = await storageRef.ref.getDownloadURL();
      // console.log("storage ref", storageRef);
      firebase
        .firestore()
        .collection("users")
        .doc(userId)
        .update({ profilePictureURL: url })
        .then((res) => {
          onSuccess();

          firebase
            .firestore()
            .collection("users")
            .doc(userId)
            .get()
            .then((doc) => {
              const userData = { id: userId, ...doc.data() };
              console.log(userData);

              dispatch({
                type: LOGIN,
                payload: {
                  uid: userData.id,
                  user: userData,
                },
              });
              dispatch({
                type: "UPDATE_PROFILE",
                payload: false,
              });
            })
            .catch((error) => {
              console.log("Error fetching updated user data:", error);
              dispatch({
                type: "UPDATE_PROFILE",
                payload: false,
              });
            });
        });
    }
  };

export const allUsers = () => async (dispatch) => {
  firebase
    .firestore()
    .collection("users")
    .onSnapshot(async (query) => {
      let userData = [];
      for (let doc of query.docs) {
        userData.push({ id: doc.id, ...doc.data() });
      }
      dispatch({ type: "GET_ALL_USERS", payload: userData });
    });
};
