/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";

import Register from "views/auth/Register.js";
import Login from "views/auth/Login.js";

import Subscriptions from "views/Subscriptions";

import ForgetPassword from "views/auth/ForgetPassword";
import Packages from "views/Packages";
import ManagePackages from "views/ManagePackages";
import Settings from "views/settings";
import Site from "views/Site";
import Users from "views/Users";
import SubscritpedSite from "views/SubscritpedSite";
import Tutorials from "views/Tutorials";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
  },
  {
    path: "/subscriptions",
    name: "My Subscriptions",
    icon: "ni ni-planet text-blue",
    component: Subscriptions,
    layout: "/admin",
  },
  {
    path: "/packages",
    name: "Packages",
    icon: "ni ni-single-02 text-yellow",
    component: Packages,
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "ni ni-key-25 text-info",
    component: Settings,
    layout: "/admin",
  },
  // {
  //   path: "/site",
  //   name: "Site",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: Site,
  //   layout: "/admin",
  // },
  {
    path: "/manage-packages",
    name: "Manage Packages",
    icon: "ni ni-key-25 text-info",
    component: ManagePackages,
    layout: "/admin",
  },

  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    isMenu: false,
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
    isMenu: false,
  },
  {
    path: "/forgetPassword",
    name: "Forget Password",
    icon: "ni ni-circle-08 text-pink",
    component: ForgetPassword,
    layout: "/auth",
    isMenu: false,
  },
  {
    path: "/users",
    name: "Users",
    icon: "ni ni-circle-08 text-pink",
    component: Users,
    layout: "/admin",
  },
  {
    path: "/tutorials ",
    name: "My Tutorials",
    icon: "ni ni-circle-08 text-pink",
    component: Tutorials,
    layout: "/admin",
  },
];
export default routes;
