const initialState = {
  sites: [],
};

export default function siteReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case "GET_SITES":
      return {
        ...state,
        sites: payload,
      };

    default:
      return { ...state };
  }
}
