import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { updateProfilePicture } from "store/actions/authAction";

const UpdateProfileModal = ({ showModal, toggleModal, setShowModal }) => {
  const dispatch = useDispatch();

  const { uid } = useSelector((state) => state.authUser);
  const { updateProfile } = useSelector((state) => state.auth);
  const [selectedImage, setSelectedImage] = useState(null);
  const [profile, setProfile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const fileType = file.type;
      const allowedTypes = ["image/jpeg", "image/png"];

      if (allowedTypes.includes(fileType)) {
        setProfile(file);
        setSelectedImage(URL.createObjectURL(file));
      } else {
        // Show error message that only JPG and PNG files are allowed
        alert("Only JPG and PNG files are allowed.");
        toggleModal();
        setProfile(null);
        setSelectedImage(null);
      }
    } else {
      // User canceled the file selection
      setProfile(null);
      setSelectedImage(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      updateProfilePicture(uid, profile, () => {
        setSelectedImage(null);
        setProfile(null);
        setShowModal(false);
      })
    );
  };

  return (
    <div>
      <Modal isOpen={showModal} toggle={toggleModal}>
        <ModalHeader>Update Your Profile Image</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Input
                type="file"
                accept=".jpg, .jpeg, .png"
                required
                onChange={handleFileChange}
              ></Input>
            </FormGroup>
            <div className="text-center">
              {selectedImage && (
                <img
                  src={selectedImage}
                  alt="Preview"
                  className="img-fluid"
                  style={{ maxHeight: "200px" }}
                />
              )}
            </div>
            <div className="d-flex justify-content-end">
              <Button size="sm" color="primary">
                {updateProfile ? <Spinner size="sm" /> : "Save"}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default UpdateProfileModal;
