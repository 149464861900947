// Root Reducer

import { combineReducers } from "redux";
import authReducer from "./authReducer";
import authUserReducer from "./authUser";
import packagesReducer from "./packagesReducer";
import siteReducer from "./siteReducer";
import subscriptionsReducer from "./subscriptionReducer";

export const appReducer = combineReducers({
  auth: authReducer,
  authUser: authUserReducer,
  packages: packagesReducer,
  subscriptions: subscriptionsReducer,
  site: siteReducer,
  
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
export default rootReducer;
