import firebase from "../../config/firebase";
import { ref, uploadBytes } from "firebase";
import { v4 as uuidv4 } from "uuid";
export const getSubscribedPackagesLoader = (val) => async (dispatch) => {
  dispatch({ type: "SUBSCRIPTIONS_LOADER", payload: val });
};
export const NotSubscribedPackagesLoader = (val) => async (dispatch) => {
  dispatch({ type: "NOT_SUBSCRIBED_LOADER", payload: val });
};
export const getAllPackagesAction = () => async (dispatch) => {
  try {
    await firebase
      .firestore()
      .collection("packages")
      .onSnapshot((querySnapshot) => {
        const packages = [];

        querySnapshot.forEach((doc) => {
          packages.push({
            id: doc.id,
            ...doc.data(),
          });
        });

        dispatch({
          type: "GET_PACKAGES",
          payload: packages,
        });
      });
  } catch (error) {
    console.log(error);
  }
};
export const packageUpdateAction = (id, obj, newImage, onSuccess = () => {}) => async (dispatch) => {
  const handleImageLoader = (isLoading) => {
    dispatch({
      type: "IMAGE_LOADER",
      payload: isLoading,
    });
  };
  try {
    handleImageLoader(true);
    const packageRef = firebase.firestore().collection("packages").doc(id);
    let updatedPackageLink = obj.packageLink;
    if (typeof obj.packageLink === "string") {
      updatedPackageLink = obj.packageLink;
    } else if (!Array.isArray(updatedPackageLink)) {
      const packageData = await packageRef.get();
      const firestorePackageLink = packageData.data().packageLink;
      updatedPackageLink = firestorePackageLink;
    } else {
      const packageData = await packageRef.get();
      const firestorePackageLink = packageData.data().packageLink;
      updatedPackageLink = firestorePackageLink.map((linkObj) => {
        const matchingObj = obj.packageLink.find((obj) => obj.name === linkObj.name);
        return matchingObj ? matchingObj : linkObj;
      });
    }
    await packageRef.update({
      packageName: obj?.packageName,
      packageLink: updatedPackageLink,
      packagePrice: obj?.packagePrice,
      included: obj?.included,
    });
    handleImageLoader(false);

    if (newImage) {
      let filName1 = newImage.name;
      let filName2 = filName1.slice(filName1.lastIndexOf("."));
      let filName3 = id + filName2.toLowerCase();
      handleImageLoader(true);
      let storageRef1 = await firebase.storage().ref(filName3).put(newImage);
      let url1 = await storageRef1.ref.getDownloadURL();
      await packageRef.update({
        packageImageURL: url1,
      });
      handleImageLoader(false);

      if (url1) {
        handleImageLoader(true);
        await firebase.storage().refFromURL(obj?.packageImageURL).delete();
        handleImageLoader(false);
      }
    }
    onSuccess();
  } catch (error) {
    console.log(error);
    handleImageLoader(false);
  }
};
export const getNotSubscribedPackages =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    // const packageIds = payload.map((subscription) => subscription.packageId);
    dispatch(NotSubscribedPackagesLoader(true));
    const activePackageIds = payload
      .filter((subscription) => subscription.status === "ACTIVE")
      .map((subscription) => subscription.packageId);
    try {
      await firebase
        .firestore()
        .collection("packages")
        .onSnapshot((querySnapshot) => {
          const filteredPackages = [];

          querySnapshot.forEach((doc) => {
            if (!activePackageIds.includes(doc.id)) {
              filteredPackages.push({
                id: doc.id,
                ...doc.data(),
              });
            }
          });

          dispatch({
            type: "GET_FILTERED_PACKAGES",
            payload: filteredPackages,
          });
          onSuccess(false);
          dispatch(NotSubscribedPackagesLoader(false));
        });
    } catch (error) {
      console.log(error);
      onSuccess(false);
      dispatch(NotSubscribedPackagesLoader(false));
    }
  };

export const getSubscribedPackages =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(getSubscribedPackagesLoader(true));
    const activePackageIds = payload
      .filter((subscription) => subscription.status === "ACTIVE")
      .map((subscription) => subscription.packageId);
    try {
      await firebase
        .firestore()
        .collection("packages")
        .onSnapshot((querySnapshot) => {
          const filteredPackages = querySnapshot.docs
            .filter((doc) => activePackageIds.includes(doc.id))
            .map((doc) => ({ id: doc.id, ...doc.data() }));
          dispatch({
            type: "GET_SUBSCRIBED_PACKAGES",
            payload: filteredPackages,
          });

          // console.log(filteredPackages, "filteredPackages");
          dispatch(getSubscribedPackagesLoader(false));
          onSuccess();
        });
    } catch (error) {
      console.log(error);
      onSuccess();

      dispatch(getSubscribedPackagesLoader(false));
    }
  };
