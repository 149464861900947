import Repository from "./Repository";
import LiveRepository from "./LiveRepository";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
const PRODUCTS = "/catalogs/products";
const PACKAGES = "/billing/plans";
const TOKEN = "/oauth2/token";

export default {
  async tokenGenerate(mode) {
    const params = new URLSearchParams();

    params.append("grant_type", "client_credentials");

    if (mode === "live") {
      try {
        const response = await axios.get(
          "https://us-central1-streams-blue.cloudfunctions.net/app/paypal"
        );
        response.data.data.mode = mode;
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    } else {
      const clientTestUser =
        // "Ac1W0YzPDGD31xq0SQmhPqcOGnTC3tWFSr0a3rwVqXB54GZyfSPHV6-51e8VWt8HZTcm1Ug6UMmNhHTH";
        "Aatp4U44ugdGzPymZJM7GJIm0P5EmcEWmCvTXPAKvRonwzUk5hEtg51uTAEPFGKKkYIVngo92sVRvsvN";
      const secretTestUser =
        "EIVM9rlOJ3g5wtA8Ow3G1EGl-aQAD_U-dmMRhSBiQqHNrvui-nhxy9nW-egg1wkR9gv1pd8wAnpxuYBS";
      return Repository.post(`${TOKEN}`, params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        auth: {
          username: clientTestUser,
          password: secretTestUser,
        },
      });
    }
  },

  getProductID(token, mode) {
    if (mode === "live") {
      console.log("Inside Live");
      return LiveRepository.post(
        `${PRODUCTS}`,
        {
          name: "Package",
          description: "package bot",
          id: uuidv4,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else {
      return Repository.post(
        `${PRODUCTS}`,
        {
          name: "Package",
          description: "package bot",
          id: uuidv4,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }
  },
  getSubscription(ID, token, amount, mode) {
    if (mode === "live") {
      return LiveRepository.post(
        `${PACKAGES}`,
        {
          product_id: ID,
          name: "Buying Package Plan",
          description: "Plan Created For Package Subscription",
          status: "ACTIVE",
          billing_cycles: [
            {
              frequency: {
                interval_unit: "MONTH",
                interval_count: 1,
              },
              tenure_type: "REGULAR",
              sequence: 1,
              total_cycles: "12",
              pricing_scheme: {
                fixed_price: {
                  value: amount,
                  currency_code: "USD",
                },
              },
            },
          ],
          payment_preferences: {
            auto_bill_outstanding: true,
            setup_fee: {
              value: "0",
              currency_code: "USD",
            },
            setup_fee_failure_action: "CONTINUE",
            payment_failure_threshold: 1,
          },
          taxes: {
            percentage: "0",
            inclusive: false,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else {
      return Repository.post(
        `${PACKAGES}`,
        {
          product_id: ID,
          name: "Buying Package Plan",
          description: "Plan Created For Package Subscription",
          status: "ACTIVE",
          billing_cycles: [
            {
              frequency: {
                interval_unit: "MONTH",
                interval_count: 1,
              },
              tenure_type: "REGULAR",
              sequence: 1,
              total_cycles: "12",
              pricing_scheme: {
                fixed_price: {
                  value: amount,
                  currency_code: "USD",
                },
              },
            },
          ],
          payment_preferences: {
            auto_bill_outstanding: true,
            setup_fee: {
              value: "0",
              currency_code: "USD",
            },
            setup_fee_failure_action: "CONTINUE",
            payment_failure_threshold: 1,
          },
          taxes: {
            percentage: "0",
            inclusive: false,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }
  },

  canncelSubscription(id, token, mode) {
    console.log(mode,'mode')
    console.log(token,'token')
    if (mode === "live") {
      return LiveRepository.post(
        `/billing/subscriptions/${id}/cancel`,
        {
          reason: "User Want Cancel Subscription",
          note: "Customer cancel the product",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else {
      return Repository.post(
        `/billing/subscriptions/${id}/cancel`,
        {
          reason: "User Want Cancel Subscription",
          note: "Customer cancel the product",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }
  },
  getSubscriptionHistory(id, startDate, endDate, token, mode) {
    if (mode === "live") {
      return LiveRepository.get(
        `/billing/subscriptions/${id}/transactions`,

        {
          params: {
            start_time: startDate,
            end_time: endDate,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else {
      return Repository.get(
        `/billing/subscriptions/${id}/transactions`,

        {
          params: {
            start_time: startDate,
            end_time: endDate,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }
  },
};
