import React from "react";
import { Link } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function PackageInfo({ packageModal, togglePackageModal, item }) {
  const handleClick = () => {
    alert("Need to Subscribe First.");
  };
  return (
    <Modal isOpen={packageModal} toggle={togglePackageModal}>
      <ModalHeader toggle={togglePackageModal} className="pb-0">
        Package Information
      </ModalHeader>
      <ModalBody>
        <ul className="pl-3">
          {item?.included?.map((items, index) => {
        
            return (
              <li className="m-0" key={index}>
                {items}
              </li>
            );
          })}
        </ul>
        <h2 className="my-3">{item?.packageName}</h2>

        <h3>Link to your Favourite {item?.packageName}</h3>
        {/* <Link to={item?.link}>{item?.packageLink}</Link> */}
        {/* {console.log(item, "item")} */}
        {item?.type == "combine" ? (
          item?.packageLink.map((data, index) => {
    
            return (
              <h4
                className="text-primary cursor-pointer"
                onClick={() => handleClick()}
                key={index}
              >
                {data?.name} Link
              </h4>
            );
          })
        ) : (
          <h4
            className="text-primary cursor-pointer"
            onClick={() => handleClick()}
          >
            Visit Site Url
          </h4>
        )}
      </ModalBody>
    </Modal>
  );
}

export default PackageInfo;
