import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { paypalSubscription } from "store/actions/subscriptions";
import { addSubscription } from "store/actions/subscriptions";
import firebase from "../config/firebase";
export const Paypal = ({ getPaypalDetail, planID, clientId, setCheckOut }) => {
  const paypal = useRef();
  useEffect(() => {
    window.paypal
      .Buttons({
        createSubscription: function (data, actions) {
          return actions.subscription.create({
            intent: "subscription",
            plan_id: planID, // Creates the subscription
          });
        },
        onApprove: async (data, actions) => {
          getPaypalDetail(data);
          setCheckOut(false);
        },
      })
      .render(paypal.current);
  }, [planID]);

  // useEffect(() => {
  //   const loadPaypalScript = async () => {
  //     const paypalScriptUrl = `https://www.paypal.com/sdk/js?client-id=${clientId}`;

  //     const script = document.createElement("script");
  //     script.src = paypalScriptUrl;
  //     script.async = true;
  //     script.onload = () => {
  //       window.paypal
  //         .Buttons({
  //           createSubscription: function (data, actions) {
  //             return actions.subscription.create({
  //               intent: "subscription",
  //               plan_id: planID,
  //             });
  //           },
  //           onApprove: async (data, actions) => {
  //             getPaypalDetail(data);
  //             setCheckOut(false);
  //           },
  //         })
  //         .render(paypal.current);
  //     };

  //     document.body.appendChild(script);
  //   };

  //   if (planID) {
  //     loadPaypalScript();
  //   }

  //   return () => {
  //     // Cleanup code if needed
  //   };
  // }, [planID]);
  return <>{planID ? <div ref={paypal}></div> : ""}</>;
  // const { uid } = useSelector((state) => state.authUser);
  // const paypal = useRef();
  // const dispatch = useDispatch();
  // const getPaypalDetail = (details) => {
  //   console.log(details, "<<details");

  //   let obj = {
  //     paymentId: details?.id,
  //     userId: uid,
  //     amount: details.purchase_units[0].amount,
  //     createAt: firebase.firestore.Timestamp.now(),
  //     packageId: props?.item?.id,
  //   };
  //   dispatch(paypalSubscription(props.item?.packagePrice, obj));
  //   // dispatch(
  //   //   addSubscription(obj, () => {
  //   //     alert("subscription added");
  //   //   })
  //   // );
  // };

  // useEffect(() => {
  //   window.paypal
  //     .Buttons({
  //       createSubscription: function (data, actions) {
  //         return actions.subscription.create({
  //           intent: "subscription",
  //           // plan_id: props.planID, // Creates the subscription
  //         });
  //       },
  //       onApprove: async (data, actions) => {
  //         // const order = await actions.order.capture();
  //         getPaypalDetail(data);
  //       },
  //     })
  //     .render(paypal.current);
  //   // window.paypal
  //   //   .Buttons({
  //   //     createOrder: (data, actions, err) => {
  //   //       return actions.order.create({
  //   //         intent: "subscription",
  //   //         purchase_units: [
  //   //           {
  //   //             description: props?.item?.packageName,
  //   //             amount: {
  //   //               currency_code: "USD",
  //   //               value: parseInt(props.item?.packagePrice),
  //   //             },
  //   //           },
  //   //         ],
  //   //       });
  //   //     },
  //   //     onApprove: async (data, actions) => {
  //   //       const order = await actions.order.capture();

  //   //       getPaypalDetail(order);

  //   //       props.setCheckOut(false);
  //   //     },
  //   //     onError: (err) => {
  //   //       console.log(err);
  //   //     },
  //   //   })
  //   //   .render(paypal.current);
  // }, []);

  // return (
  //   <>
  //     <div ref={paypal}></div>
  //   </>
  // );
};
