import OnlyHeader from "components/Headers/OnlyHeader";
import EditPackage from "components/modals/EditPackage";
import SubscribedPackages from "components/SubscribedPackages";
import { packages } from "helpers/packages";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { getAllPackagesAction } from "store/actions/packagesAction";

function ManagePackages() {
  const { packages } = useSelector((state) => state?.packages);
  // const { packages } = useSelector((state) => state?.packages);
  const [packageEditModal, setPackageEditModal] = useState(false);
  const [currentPackage, setCurrentPackage] = useState(null);
  const [newImage, setNewImage] = useState();
  const togglePackageEditModal = () => setPackageEditModal(!packageEditModal);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllPackagesAction());
  }, []);

  return (
    <div>
      <EditPackage
        packageEditModal={packageEditModal}
        togglePackageEditModal={togglePackageEditModal}
        currentPackage={currentPackage}
        setNewImage={setNewImage}
        newImage={newImage}
        setCurrentPackage={setCurrentPackage}
      />
      <OnlyHeader />
      <Container className="mt--5 skills" fluid>
        <Row className="my-3">
          <Col xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col className="d-flex align-items-center justify-content-between">
                    <div className="d-flex ">
                      <h3>Manage Packages</h3>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Package Name</th>
                        <th>Package URL</th>
                        <th>Package Price</th>
                        <th>Package Image</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {packages?.map((item) => {
                        return (
                          <>
                            {item?.type == "combine" ? (
                              item?.packageLink?.map((data,index) => {
                                console.log(item,'data')
                                return (
                                  <tr key={data.link}>
                                    <td>{item?.packageName}</td>
                                    <td>{data?.link}</td>
                                    <td>{item?.packagePrice}</td>
                                    <td>
                                      <img
                                        src={item?.packageImageURL}
                                        height="30%"
                                        width="30%"
                                      />
                                    </td>
                                    <td>
                                      <Button
                                        size="sm"
                                        color="primary"
                                        onClick={() => {
                                          togglePackageEditModal();
                                          setCurrentPackage({
                                            id: item?.id,
                                            packageName: item?.packageName,
                                            packagePrice: item?.packagePrice,
                                            packageLink: {link:data?.link,name:data?.name},
                                            packageImageURL: item?.packageImageURL,
                                            included: item?.included,
                                            type: item?.type,
                                            index: index,
                                          });
                                        }}
                                      >
                                        Edit Package
                                      </Button>
                                    </td>
                                  </tr>
                                );
                              })
                              
                            ) : (
                              
                              <tr>
                                <td>{item?.packageName}</td>
                                <td>{item?.packageLink}</td>
                                <td>{item?.packagePrice}</td>
                                <td>
                                  <img
                                    src={item?.packageImageURL}
                                    height="30%"
                                    width="30%"
                                  />
                                </td>
                                <td>
                                  <Button
                                    size="sm"
                                    color="primary"
                                    onClick={() => {
                                      togglePackageEditModal();
                                      setCurrentPackage(item);
                                    }}
                                  >
                                    Edit Package
                                  </Button>
                                </td>
                              </tr>
                            )}
                          </>
                        );
                      })}
                    </tbody>
                  </Table>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ManagePackages;
