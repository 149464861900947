/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import { type } from "jquery";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { allUsers } from "store/actions/authAction";
import { getCurrentUserSubscriptions } from "store/actions/subscriptions";

const Header = () => {
  const dispatch = useDispatch();
  const { allUser, uid, user } = useSelector((state) => state.authUser);
  const { currentUserSubscriptions } = useSelector(
    (state) => state.subscriptions
  );

  const [totalSpent, setTotalSpent] = useState("");
  const [nextPayment, setNextPayment] = useState("NaN");
  const [totalUsers, setTotalUsers] = useState("");
  const [totalEarning, setTotalEarning] = useState("NaN");
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    if (allUser) {
      let temp = 0.0;

      allUser?.forEach((item) => {
        if (item.id == uid) {
          const totalSpent = parseFloat(item.totalSpent);
          if (!isNaN(totalSpent)) {
            temp = +totalSpent;
          }
        }
      });
      setTotalSpent(temp?.toFixed(2));
    }
  }, [allUser]);
  useEffect(() => {
    dispatch(allUsers());
    dispatch(getCurrentUserSubscriptions(uid));
  }, []);
  // useEffect(() => {
  //   if (currentUserSubscriptions?.length > 0) {
  //     const currentDate = new Date().getDate();
  //     const nextPayment = currentUserSubscriptions
  //       .filter(
  //         (sub) =>
  //           sub.status === "ACTIVE" &&
  //           new Date(sub.updated_at.seconds * 1000).getDate() > currentDate
  //       )
  //       .sort((a, b) => a.updated_at.seconds - b.updated_at.seconds)[0];
  //     console.log(nextPayment, "nextPayment");
  //     if (nextPayment) {
  //       const nextPaymentDate = new Date(
  //         nextPayment.updated_at.seconds * 1000 +
  //           nextPayment.updated_at.nanoseconds / 1000000
  //       )
  //         .toLocaleDateString("en-US", {
  //           day: "2-digit",
  //           month: "2-digit",
  //           year: "numeric",
  //         })
  //         .split("/");
  //       console.log(nextPaymentDate, "nextPaymentDate");
  //       const formattedDate = `${nextPaymentDate[1]}-${nextPaymentDate[0]}-${nextPaymentDate[2]}`;
  //       setNextPayment(formattedDate);
  //       setLoader(false);
  //     } else {
  //       const comingPayment = currentUserSubscriptions.filter(
  //         (sub) =>
  //           sub.status === "ACTIVE" &&
  //           new Date(sub.updated_at.seconds * 1000).getDate() == today
  //       );
  //       if (comingPayment) {
  //         var today = new Date();
  //         var dd = String(today.getDate()).padStart(2, "0");
  //         var mm = String(today.getMonth() + 2).padStart(2, "0");
  //         var yyyy = today.getFullYear();
  //         today = dd + "-" + mm + "-" + yyyy;
  //         setNextPayment(today);
  //       }
  //       setLoader(false);
  //     }
  //   }
  // }, [currentUserSubscriptions]);

  ///////////////TESTING//////
  useEffect(() => {
    if (!currentUserSubscriptions || currentUserSubscriptions.length === 0) {
      return;
    }

    const today = new Date();
    const comingPayment = currentUserSubscriptions.find(
      (sub) =>
        sub.status === "ACTIVE" &&
        new Date(sub.updated_at.seconds * 1000).getTime() > today.getTime()
    );
    if (comingPayment) {
      const date = new Date(comingPayment.updated_at.seconds * 1000);
      const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${date.getFullYear().toString()}`;
      setNextPayment(formattedDate);
    } else {
      const nextPayment = currentUserSubscriptions
        .filter((sub) => sub.status === "ACTIVE")
        .sort((a, b) => a.updated_at.seconds - b.updated_at.seconds)[0];
      if (nextPayment) {
        // const nextPaymentDate = new Date(
        //   nextPayment.updated_at.seconds * 1000 +
        //     nextPayment.updated_at.nanoseconds / 1000000
        // )
        //   .toLocaleDateString("en-US", {
        //     day: "2-digit",
        //     month: "2-digit",
        //     year: "numeric",
        //   })
        //   .split("/")
        //   .reverse()
        //   .join("-");
        const date = new Date(nextPayment.updated_at.seconds * 1000);
        const nextPaymentDate = `${date
          .getDate()
          .toString()
          .padStart(2, "0")}-${(date.getMonth() + 2)
          .toString()
          .padStart(2, "0")}-${date.getFullYear().toString()}`;
        setNextPayment(nextPaymentDate);
      } else {
        setNextPayment("NaN");
      }
    }
    setLoader(false);
  }, [currentUserSubscriptions]);

  ///////////////
  useEffect(() => {
    if (allUser) {
      // filter the array to exclude admin users
      const nonAdminUsers = allUser.filter((user) => user.role !== "admin");

      // compute the total earnings
      const Earnings = nonAdminUsers.reduce((accumulator, currentValue) => {
        return parseFloat(accumulator) + parseFloat(currentValue.totalSpent);
      }, 0);
      setTotalEarning(Earnings?.toFixed(2));
      setTotalUsers(nonAdminUsers?.length);
      setLoader(false);
    }
  }, [allUser]);

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            {user?.role == "customer" ? (
              <Row>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Amount Spent
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            ${totalSpent}
                          </span>
                        </div>
                        {/* <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-chart-bar" />
                        </div>
                      </Col> */}
                      </Row>
                      {/* <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 3.48%
                      </span>{" "}
                      <span className="text-nowrap">Since last month</span>
                    </p> */}
                    </CardBody>
                  </Card>
                </Col>

                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Next Payment
                          </CardTitle>
                          {loader ? (
                            <Spinner size={"sm"} />
                          ) : (
                            <span className="h2 font-weight-bold mb-0">
                              {/* 02-11-2023 */}
                              {nextPayment}
                            </span>
                          )}
                        </div>
                        {/* <Col className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="fas fa-percent" />
                        </div>
                      </Col> */}
                      </Row>
                      {/* <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-success mr-2">
                        <i className="fas fa-arrow-up" /> 12%
                      </span>{" "}
                      <span className="text-nowrap">Since last month</span>
                    </p> */}
                    </CardBody>
                  </Card>
                  {/* ////////////////////Show to Admin */}
                </Col>
              </Row>
            ) : (
              <Row>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Total Earning
                          </CardTitle>
                          {loader ? (
                            <Spinner size={"sm"} />
                          ) : (
                            <span className="h2 font-weight-bold mb-0">
                              ${totalEarning}
                            </span>
                          )}
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Total Users
                          </CardTitle>
                          {loader ? (
                            <Spinner size={"sm"} />
                          ) : (
                            <span className="h2 font-weight-bold mb-0 d-flex  align-items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                                height={15}
                                width={15}
                              >
                                <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                              </svg>{" "}
                              &nbsp; {totalUsers}
                            </span>
                          )}
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                {/* ///////////////////////////////////////////// */}
              </Row>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
