import React, { useState } from "react";
import PaypalAccountCreaction from "components/PaypalAccountCreaction";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { SignUp } from "../../store/actions/authAction";

const Register = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { registerLoading } = useSelector((state) => state.auth);
  const [creds, setCreds] = useState({
    firstName: "",
    email: "",
  });
  const [password, setPassword] = useState("");
  const [createAccount, setCreateAccount] = useState(false);
  const handleCredsChange = (name, value) => {
    setCreds((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const getPaypalDetail = (details) => {
    // console.log(details.purchase_units[0].amount.value, "<<<<details");
    dispatch(
      SignUp(
        creds,
        password,
        details.purchase_units[0].amount.value,
        history,
        () => {
          history.push("/auth/login");
        }
      )
    );
    setCreds({
      firstName: "",
      email: "",
    });
    setPassword("");
    // let obj = {
    // 	athlete_id: props.match.params.id,
    // 	fan_id: auth.uid,
    // 	payment_id: details.id,
    // 	created_at: firebase.firestore.Timestamp.now(),
    // 	amount: details.purchase_units[0].amount,
    // 	downloads: 1,
    // 	via: 'paypal',
    // };
    // dispatch(updatePaypalEmail(auth.uid, details.payer.email_address));
    // dispatch(
    // 	licensePurchase({
    // 		obj,
    // 		onSuccess: () => {
    // 			toast.success(
    // 				'Licenses purchased successfully.You can view you purchased badges in My Badges Section'
    // 			);
    // 		},
    // 	})
    // );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      SignUp(
        creds,
        password,
        // details.purchase_units[0].amount.value,
        history,
        () => {
          history.push("/auth/login");
        }
      )
    );
    setCreds({
      firstName: "",
      email: "",
    });
    setPassword("");
    // history.push("/auth/login");
  };
  return (
    <>
      <Col lg="6" md="8">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Sign up</small>
            </div>
            <Form
              onSubmit={handleSubmit}
              // onSubmit={(e) => {
              //   e.preventDefault();
              //   setCreateAccount(true);
              // }}
            >
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Name"
                    type="text"
                    name="firstName"
                    required
                    value={creds.firstName}
                    onChange={(e) =>
                      handleCredsChange("firstName", e.target.value)
                    }
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    name="email"
                    required
                    value={creds.email}
                    onChange={(e) => handleCredsChange("email", e.target.value)}
                    autoComplete="new-email"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>

              <Row className="my-4">
                <Col xs="12">
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="customCheckRegister"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheckRegister"
                    >
                      <span className="text-muted">
                        I agree with the{" "}
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Privacy Policy
                        </a>
                      </span>
                    </label>
                  </div>
                </Col>
              </Row>
              <div className="text-center">
                <Button
                  className="mt-4"
                  color="primary"
                  type="submit"
                  disabled={registerLoading}
                >
                  {registerLoading ? <Spinner size="md" /> : "Create Account"}
                </Button>
              </div>
              {createAccount ? (
                <div className="px-2 my-3 ">
                  {" "}
                  <h5>You have pay 50$ for the Creation of Account</h5>
                  <PaypalAccountCreaction
                    price={50}
                    getPaypalDetail={getPaypalDetail}
                  />
                </div>
              ) : (
                ""
              )}
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6"></Col>
          <Col className="text-right text-light" xs="6">
            <Link to="/auth/login" className="text-light">
              <small>Already have an account ?</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Register;
