import OnlyHeader from "components/Headers/OnlyHeader";
import TransactionHistory from "components/modals/TransactionHistoryModal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Table,
  Button,
  Avatar,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Spinner,
} from "reactstrap";
import { allUsers } from "store/actions/authAction";
import { subscriptionHistory } from "store/actions/subscriptions";
import { getAllActiveSubscriptions } from "store/actions/subscriptions";

const Users = () => {
  const dispatch = useDispatch();
  const { allUser } = useSelector((state) => state.authUser);
  const { activeSubscriptions, historyLoader } = useSelector(
    (state) => state.subscriptions
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [userTransactions, setUserTransactions] = useState();
  const [packageName, setpackageName] = useState("");
  const [modal, setModal] = useState(false);
  const [isSelected, setIsSelected] = useState("");

  const toggle = () => setModal(!modal);

  useEffect(() => {
    dispatch(allUsers());
    dispatch(getAllActiveSubscriptions());
  }, []);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  // const getUserActivePackage = (item) => {
  //   const userActivePackages = activeSubscriptions?.filter(
  //     (subscription) => subscription.userId === item.id
  //   );
  //   return userActivePackages;

  //   //   if(activeSubscriptions?.filter(subscription=> subscription.userId === item.id).length>0){activeSubscriptions?.filter(subscription=> subscription.userId === item?.id)?.map((ele) => {
  //   //   return  <span className="rounded-pill px-3 py-1 mb-0 mx-1 bg-warning text-white">
  //   //       {ele?.packageName}
  //   //     </span>
  //   // })
  // };
  const sortTable = (option) => {
    setSelectedOption(option);
    switch (option) {
      case "name":
        allUser.sort((a, b) => a.firstName.localeCompare(b.firstName));
        break;
      case "amount":
        allUser.sort((a, b) => a.totalSpent - b.totalSpent);
        break;
      default:
        break;
    }
  };
  const [searchQuery, setSearchQuery] = useState("");

  // const filteredUsers = allUser?.filter(
  //   (user) =>
  //     user.role !== "admin" &&
  //     (user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //       user.email.toLowerCase().includes(searchQuery.toLowerCase()))
  // );

  const getTranscationHistory = async (e, subscription) => {
    e.preventDefault();

    const endDate = new Date();
    const startDate = new Date(endDate); // Make a copy of current date
    // startDate.setMonth(endDate.getMonth() - 3); // Subtract 3 months
    startDate.setDate(startDate.getDate() - 5);

    // toggle();
    dispatch(
      subscriptionHistory(
        subscription?.subscriptionId,
        startDate.toISOString(),
        endDate.toISOString(),
        (response) => {
          if (response?.data?.transactions.length > 0) {
            setUserTransactions(response?.data?.transactions);
            setpackageName(subscription?.packageName);
            toggle();
          } else {
            alert("Not yet any transaction perform!");
          }
        }
      )
    );
  };

  return (
    <>
      <TransactionHistory
        modal={modal}
        toggle={toggle}
        userTransactions={userTransactions}
        packageName={packageName}
      />
      <OnlyHeader />
      <Container className="mt-n5 skills" fluid>
        <Row>
          {/* <select
            className="form-select mb-3"
            value={sortValue}
            onChange={(e) => setSortValue(e.target.value)}
          >
            <option value="none">Sort by:</option>
            <option value="name">Name</option>
            <option value="amount">Amount Spent</option>
          </select> */}
        </Row>
        <Row className="my-3">
          <Col xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3>All Users</h3>
                <Row className="py-2 mx-1 ">
                  <Col
                    md={8}
                    className="d-flex flex-column justify-content-center search-bar  align-items-center"
                  >
                    {" "}
                    <div className="px-3 rounded-pill d-flex justify-content-evenly align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9.99921 1.75001C5.44286 1.75001 1.74921 5.44366 1.74921 10C1.74921 14.5564 5.44286 18.25 9.99921 18.25C14.5556 18.25 18.2492 14.5564 18.2492 10C18.2492 5.44366 14.5556 1.75001 9.99921 1.75001ZM0.249207 10C0.249207 4.61523 4.61443 0.250008 9.99921 0.250008C15.384 0.250008 19.7492 4.61523 19.7492 10C19.7492 15.3848 15.384 19.75 9.99921 19.75C4.61443 19.75 0.249207 15.3848 0.249207 10Z"
                          fill="#474967"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M19.353 16.9698C18.5835 16.964 17.8911 17.2675 17.4687 17.854C17.0466 18.4399 16.9762 19.1941 17.2176 19.925L17.2178 19.9257C17.5197 20.8372 18.1095 21.6168 19.0151 21.7382C19.9206 21.8595 20.6944 21.2628 21.2245 20.4648L21.2245 20.4647C21.7282 19.7063 21.9417 18.8156 21.5322 18.0508C21.1228 17.2864 20.2641 16.9706 19.353 16.9698ZM18.6418 19.4543C18.8699 20.1425 19.1499 20.2428 19.2144 20.2515C19.2789 20.2601 19.5751 20.2368 19.975 19.6349M18.6418 19.4543C18.5234 19.0954 18.5929 18.8597 18.6858 18.7307C18.7778 18.603 18.974 18.4665 19.3432 18.4698L19.3432 18.4698H19.3497C19.9774 18.4698 20.1671 18.6792 20.2098 18.7589C20.2527 18.839 20.3213 19.1133 19.975 19.6348"
                          fill="#474967"
                        />
                      </svg>
                      <Input
                        className="ml-2"
                        type="text"
                        placeholder="Search by name or email"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        style={{ boxShadow: "none" }}
                      />
                    </div>
                  </Col>
                  <Col className="d-flex flex-column  align-items-center mt-md-0 mt-2">
                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                      <DropdownToggle className="rounded-pill" caret>
                        Sort By: {selectedOption || "None"}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => sortTable("name")}>
                          Name
                        </DropdownItem>
                        <DropdownItem onClick={() => sortTable("amount")}>
                          Amount Spent
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                  {/* Search bar */}
                </Row>
              </CardHeader>
              <CardBody>
                <Table responsive striped className="my-table">
                  <thead className="bg-primary text-white">
                    <tr>
                      <th>
                        <h5 className="mb-0 text-white">Name</h5>
                      </th>
                      <th>
                        <h5 className="mb-0 text-white">Email</h5>
                      </th>
                      <th>
                        <h5 className="mb-0 text-white">Amount Spent</h5>
                      </th>
                      <th>
                        <h5 className="mb-0 text-white">
                          subscription packages name
                        </h5>
                      </th>{" "}
                    </tr>
                  </thead>
                  <tbody>
                    {allUser &&
                      allUser
                        ?.filter((user) => user.role !== "admin")
                        .filter((user) =>
                          searchQuery
                            ? user.firstName
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase()) ||
                              user.email
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())
                            : true
                        )
                        .map((item) => {
                          return (
                            <tr key={item.id}>
                              <td>
                                <div className="mt-2 d-flex justify-content-start align-items-center">
                                  {item?.firstName}
                                </div>
                              </td>{" "}
                              <td>
                                <div className="mt-2 w-100 d-flex justify-content-start align-items-center">
                                  {item?.email}
                                </div>
                              </td>{" "}
                              <td>
                                <div className="mt-2 w-100 d-flex justify-content-center align-items-center">
                                  {Number(item?.totalSpent).toFixed(2)}
                                </div>
                              </td>{" "}
                              <td>
                                <div className="mt-2 w-100 d-flex justify-content-center align-items-center">
                                  <Row>
                                    {activeSubscriptions.filter(
                                      (subscription) =>
                                        subscription.userId === item.id
                                    ).length > 0
                                      ? activeSubscriptions
                                          ?.filter(
                                            (subscription) =>
                                              subscription.userId === item.id
                                          )
                                          .map((ele) => {
                                            return (
                                              <Col className="p-0">
                                                {/* -------Working------ */}
                                                <button
                                                  disabled={historyLoader}
                                                  className="rounded-pill px-3 py-1 mb-0 mx-1 bg-warning text-white cursor-pointer border border-none"
                                                  onClick={(e) => {
                                                    setIsSelected(ele?.id);
                                                    getTranscationHistory(
                                                      e,
                                                      ele
                                                    );
                                                  }}
                                                >
                                                  {historyLoader &&
                                                  ele.id == isSelected ? (
                                                    <Spinner size={"sm"} />
                                                  ) : (
                                                    ele?.packageName
                                                  )}
                                                </button>
                                              </Col>
                                            );
                                          })
                                      : "none"}
                                  </Row>
                                </div>
                              </td>{" "}
                            </tr>
                          );
                        })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Modal Title</ModalHeader>
          <ModalBody>Modal content goes here</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>
              Save changes
            </Button>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal> */}
      </Container>
    </>
  );
};

export default Users;
