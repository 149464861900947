import React from "react";
import { Link } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { useHistory } from "react-router-dom";

function SubscribedInfoModal({
  subscribedModal,
  toggleSubscribedModal,
  item,
  subscriptedPackage,
}) {
  const history = useHistory();
  // console.log(subscriptedPackage,"<<<--")
  function handleClick(item) {
    const state = {
      url:
        item?.type == "combine"
          ? item?.packageLink.map((data) => {
              return data.link;
            })
          : item.packageLink,
      subscriptedPackage: subscriptedPackage,
    };
    toggleSubscribedModal(!subscribedModal);

    history.push(
      `/admin/subscriptions/${subscriptedPackage.id}/${state.url}`,
      state
    );
  }

  return (
    <Modal isOpen={subscribedModal} toggle={toggleSubscribedModal}>
      <ModalHeader toggle={toggleSubscribedModal} className="pb-0">
        Subscriptions Information
      </ModalHeader>
      <ModalBody>
        <ul className="pl-3">
          {item?.included?.map((items, idx) => {
            return <li className="m-0">{items}</li>;
          })}
        </ul>
        <h2 className="my-3">{item?.packageName}</h2>
        <h3>Link to your Favourite {item?.packageName}</h3>
        {/* <Link to={item?.packageLink}>{item?.packageLink}</Link> */}
        {/* <Link to="/admin/subscriptions/abc"> */}
        {/* {item?.packageLink != "http://listen.is/" ? (
          item?.type == "combine" ? (
            item?.packageLink.map((data) => {
              return (
                <h4
                  className="text-primary cursor-pointer"
                  onClick={() => handleClick(item)}
                >
                  {data.name}
                </h4>
              );
            })
          ) : (
            <h4
              className="text-primary cursor-pointer"
              onClick={() => handleClick(item)}
            >
              Visit Site Url
            </h4>
          )
        ) : (
          <Link
            to={item?.packageLink}
            rel="noreferrer"
            target={"_blank"}
            onClick={() => {
              toggleSubscribedModal(!subscribedModal);
              window.open(item?.packageLink, "_blank");
            }}
          >
            Visit Site Url
          </Link>
        )} */}
        {Array.isArray(item.packageLink) ? (
          item.packageLink.map((item, index) => (
            <a key={index} className="d-block" href={item.link} target="_blank">
             {item?.name} Link
            </a>
          ))
        ) : (
          <a href={item.packageLink} target="_blank">
            Visit Site Url
          </a>
        )}

        {/* <Link
          to={item?.packageLink}
          rel="noreferrer"
          target={"_blank"}
          onClick={() => {
            toggleSubscribedModal(!subscribedModal);
            window.open(item?.packageLink, "_blank");
          }}
        >
          Visit Site Url
        </Link> */}
        {/* </Link> */}
      </ModalBody>
    </Modal>
  );
}

export default SubscribedInfoModal;
