import OnlyHeader from "components/Headers/OnlyHeader";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const SubscritpedSite = () => {
  const location = useLocation();
  const state = location.state;
  useEffect(() => {
    if (!state) {
      alert("Need to subscribe it first..!");
      window.location.href = "/admin/subscriptions";
    }
  }, []);
  console.log(state);
  return (
    <>
      {/* <OnlyHeader></OnlyHeader> */}
      <div className="  bg-gradient-info py-5 bg-danger "></div>
      <div className="vh-100 w-100 ">
        <iframe
          src={state?.url}
          // style={{height:200px,width:300px}}
          className="vh-100 w-100 overflow-y-none"
          title="Example "
        ></iframe>
      </div>
    </>
  );
};
export default SubscritpedSite;
