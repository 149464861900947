const initialState = {
  packages: [],
  notSubscribedPackages: [],
  subscribedPackages: [],
  subscribedPackagesLoader: false,
  packageLoader: false,
};

export default function packagesReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case "GET_PACKAGES":
      return {
        ...state,
        packages: payload,
      };
    case "GET_FILTERED_PACKAGES":
      return {
        ...state,
        notSubscribedPackages: payload,
      };
    case "GET_SUBSCRIBED_PACKAGES":
      return {
        ...state,
        subscribedPackages: payload,
      };
    case "IMAGE_LOADER":
      return {
        ...state,
        imgLoader: payload,
      };
    case "SUBSCRIPTIONS_LOADER":
      return {
        ...state,
        subscribedPackagesLoader: payload,
      };
    case "NOT_SUBSCRIBED_LOADER":
      return {
        ...state,
        packageLoader: payload,
      };

    default:
      return { ...state };
  }
}
