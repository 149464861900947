import { initializeApp } from "firebase/app";
import firebase from "firebase";
import storage from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyBQYQ-9kUywktySenO9XJKckCfDdmSmmDA",
  authDomain: "streams-blue.firebaseapp.com",
  projectId: "streams-blue",
  storageBucket: "streams-blue.appspot.com",
  messagingSenderId: "142798505005",
  appId: "1:142798505005:web:0168853a98b7bb338be4a4",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
