import Header from "components/Headers/Header";
import OnlyHeader from "components/Headers/OnlyHeader";
import { PackageCard } from "components/PackageCard";
// import { packages } from "helpers/packages";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import { getNotSubscribedPackages } from "store/actions/packagesAction";
import { getMode } from "store/actions/subscriptions";
import { getCurrentUserSubscriptions } from "store/actions/subscriptions";

function Packages() {
  const dispatch = useDispatch();

  const { notSubscribedPackages, subscribedPackagesLoader, packageLoader } =
    useSelector((state) => state.packages);
  const { userSubscriptionLoader } = useSelector(
    (state) => state.subscriptions
  );
  const { uid } = useSelector((state) => state.authUser);
  useEffect(() => {
    dispatch(
      getCurrentUserSubscriptions(uid, (response) => {
        dispatch(getNotSubscribedPackages(response));
      })
    );
  }, []);

  return (
    <>
      <OnlyHeader />
      <Container className="mt--5 skills" fluid>
        <Row className="my-3">
          <Col xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col className="d-flex align-items-center justify-content-between">
                    <div className="d-flex ">
                      <h3>Packages</h3>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  {packageLoader || userSubscriptionLoader ? (
                    <div className="d-flex justify-content-center  align-items-center w-100 text-dark ">
                      <Spinner size={"lg"} />
                    </div>
                  ) : notSubscribedPackages.length !== 0 ? (
                    notSubscribedPackages?.map((item, index) => {
                      return (
                        <Col lg={3} md={6} sm={12} key={index}>
                          <PackageCard item={item} />
                        </Col>
                      );
                    })
                  ) : (
                    <Col>
                      <div className="d-flex justify-content-center  align-items-center w-100 text-dark ">
                        <h3>You have bought all packages</h3>
                      </div>
                    </Col>
                  )}{" "}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Packages;
