import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { packageUpdateAction } from "store/actions/packagesAction";

function EditPackage({
  packageEditModal,
  togglePackageEditModal,
  currentPackage,
  setCurrentPackage,
  setNewImage,
  newImage,
}) {
  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    if (name === "packageLink") {
      if (typeof currentPackage.packageLink === "object") {
        const updatedPackageLink = [
          {
            link: value,
            name: currentPackage.packageLink.name,
          },
        ];
        setCurrentPackage((prevState) => ({
          ...prevState,
          [name]: updatedPackageLink,
        }));
      } else {
        setCurrentPackage((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else {
      setCurrentPackage((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const handleInculdeChange = (event, index) => {
    const { value } = event.target;
    setCurrentPackage((prevState) => {
      const updatedIncluded = [...prevState.included];
      updatedIncluded[index] = value;
      return { ...prevState, included: updatedIncluded };
    });
  };

  const handleNewImage = (event) => {
    const selectedFile = event.target.files[0];
    setNewImage(selectedFile);
  };

  const dispatch = useDispatch();
  const { imgLoader } = useSelector((state) => state.packages);

  return (
    <Modal isOpen={packageEditModal} toggle={togglePackageEditModal}>
      <ModalHeader toggle={togglePackageEditModal}>
        Update Package Details
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label className="mb-0 mt-2">
              <h4 className="mb-0">Package Name</h4>
            </Label>
            <InputGroup className="mb-2">
              <Input
                type="text"
                name="packageName"
                defaultValue={currentPackage?.packageName}
                onChange={handleChange}
                required
              />
            </InputGroup>

            <Label className="mb-0 mt-2">
              <h4 className="mb-0">Package Url</h4>
            </Label>
            <InputGroup className="mb-2">
              <Input
                type="text"
                name="packageLink"
                defaultValue={
                  typeof currentPackage?.packageLink === "object"
                    ? currentPackage?.packageLink.link
                    : currentPackage?.packageLink
                }
                onChange={handleChange}
              />
            </InputGroup>

            <Label className="mb-0 mt-2">
              <h4 className="mb-0">Package Price</h4>
            </Label>
            <InputGroup className="mb-2">
              <Input
                type="number"
                name="packagePrice"
                defaultValue={currentPackage?.packagePrice}
                onChange={handleChange}
              />
            </InputGroup>

            <Label className="mb-0 mt-2">
              <h4 className="mb-0">What's Included in Package</h4>
            </Label>
            {currentPackage?.included &&
              currentPackage?.included?.map((item, idx) => {
                return (
                  <InputGroup className="mb-2">
                    <Input
                      key={idx}
                      type="text"
                      name="included"
                      defaultValue={item}
                      onChange={(e) => {
                        handleInculdeChange(e, idx);
                      }}
                    />
                  </InputGroup>
                );
              })}
            <InputGroup>
              {newImage ? (
                <img
                  src={URL.createObjectURL(newImage)}
                  alt="Preview"
                  width="50%"
                  className="py-2"
                />
              ) : (
                <img
                  src={currentPackage?.packageImageURL}
                  alt="Preview"
                  width="50%"
                  className="py-2"
                />
              )}
              <Input
                type="file"
                name="packageImage"
                onChange={handleNewImage}
              />
            </InputGroup>

            <div className="d-flex justify-content-end">
              <Button
                className="my-2 "
                size="sm"
                color="primary"
                onClick={() => {
                  dispatch(
                    packageUpdateAction(
                      currentPackage?.id,
                      currentPackage,
                      newImage,
                      () => {
                        alert("Updated Successfully");
                        togglePackageEditModal();
                      }
                    )
                  );
                }}
              >
                {imgLoader ? <Spinner size="sm" /> : " Update Details"}
              </Button>
            </div>
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default EditPackage;
