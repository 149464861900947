import React, { useEffect, useState } from "react";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  CardFooter,
  Spinner,
} from "reactstrap";

import PackageInfoModal from "./modals/PackageInfoModal";
import firebase from "../config/firebase";
import { useHistory } from "react-router-dom";
import { Paypal } from "./Paypal";
import { paypalSubscription } from "store/actions/subscriptions";
import { useDispatch, useSelector } from "react-redux";
import { addSubscription } from "store/actions/subscriptions";
import { allUsers } from "store/actions/authAction";
import { getMode } from "store/actions/subscriptions";

export const PackageCard = ({ item }) => {
  const [subscription, setSubscription] = useState("");

  const [packageModal, setPackageModal] = useState(false);
  const togglePackageModal = () => setPackageModal(!packageModal);
  const [checkout, setCheckOut] = useState(false);
  const [activeButton, setActiveButton] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();

  let { uid, user, allUser } = useSelector((state) => state.authUser);
  let { subscriptionLoader, mode } = useSelector(
    (state) => state.subscriptions
  );
  const [totalSpent, setTotalSpent] = useState("");
  const getPaypalDetail = (details) => {
    // console.log(details, "<<details");
    let spentTotal = parseFloat(totalSpent) + parseFloat(item.packagePrice);
    let obj = {
      packageId: item?.id,
      packageName: item?.packageName,
      paymentId: details?.orderID,
      productID: subscription?.data?.product_id,
      subscriptionId: details?.subscriptionID,
      createAt: firebase.firestore.Timestamp.now(),
      status: subscription?.data?.status,
      userId: uid,
      updated_at: firebase.firestore.Timestamp.now(),
      nextBillingTime: "",
    };
    dispatch(addSubscription(spentTotal, uid, obj));
    // var payment = {
    // 	value: amount,
    // 	currency_code: 'USD',
    // };
    // let obj = {
    //  athlete_id: props.match.params.id,
    // 	fan_id: auth.uid,
    // 	athlete_id: athAccount,
    // 	subscription_id: details.subscriptionID,
    // 	created_at: firebase.firestore.Timestamp.now(),
    // 	updated_at: firebase.firestore.Timestamp.now(),
    // 	amount: payment,
    // 	subscriptionDuration: subscriptionMonth,
    // 	status: 'active',
    // 	// amount: details.purchase_units[0].amount,
    // 	via: 'paypal',
    // };
    // // dispatch(updatePaypalEmail(auth.uid, details.payer.email_address));
    // dispatch(
    // 	licenseSubscription({
    // 		obj,
    // 		onSuccess: () => {
    // 			toast.success('Licenses subscription successfully.');
    // 			setAmount('');
    // 			setSubscriptionMonth('');
    // 			setAthAccount('');
    // 			setCheckOut(false);
    // 		},
    // 	})
    // );
  };
  const handleSubscription = (e) => {
    e.preventDefault();
    dispatch(
      paypalSubscription(mode[0].type, item?.packagePrice, (res) => {
        setSubscription(res);
      })
    );
    setCheckOut(true);
  };
  // if (!subscriptionLoader) {
  //   setActiveButton("");
  // }
  useEffect(() => {
    let temp = "";
    allUser.map((item) => {
      if (item.id == uid) {
        return (temp = item.totalSpent);
      }
    });
    setTotalSpent(temp);
  }, [allUser]);
  useEffect(() => {
    dispatch(allUsers());
    dispatch(getMode());
  }, []);

  const liveClientId =
    "Abz5WVM5Nlwf2Izbi0_fmapwsYCFfa9qhcSmWcOqkPwIWVdELZHwBXIGqjyoxZC_tI7I1UnngZqwn-V3";
  const testClientId =
    "Aatp4U44ugdGzPymZJM7GJIm0P5EmcEWmCvTXPAKvRonwzUk5hEtg51uTAEPFGKKkYIVngo92sVRvsvN";

  useEffect(() => {
    let script = null;
    script = document.createElement("script");

    script.src = `https://www.paypal.com/sdk/js?client-id=${
      mode[0]?.type === "live" ? liveClientId : testClientId
    }&disable-funding=credit,card&vault=true`;

    script.async = true;
    document.body.appendChild(script);
  }, [mode]);

  return (
    <>
      <div className="mt-3">
        <PackageInfoModal
          packageModal={packageModal}
          togglePackageModal={togglePackageModal}
          item={item}
        />
        <Card>
          <CardImg
            top
            className="w-100 h-auto "
            style={{ aspectRatio: "3/2" }}
            src={item?.packageImageURL}
            alt="Card image cap"
          />
          <CardBody>
            <div className="d-flex justify-content-between align-items-center mt-2">
              <CardTitle className="p-0 m-0">{item?.packageName}</CardTitle>
              <Button
                size="sm"
                className="cursor-pointer"
                onClick={() => {
                  togglePackageModal();
                }}
              >
                Info
                <i className="fa fa-info mx-2"></i>
              </Button>
            </div>
            <CardSubtitle className="my-3">${item?.packagePrice}</CardSubtitle>

            {/* {item?.packageName == "Books" ? (
              <Button size="sm" color="danger">
              Unsubscribe
              </Button>
            ) : ( */}
            {/* )} */}
            <Button
              size="sm"
              color="primary"
              disabled={checkout}
              onClick={(e) => {
                handleSubscription(e);
                setActiveButton(item?.id);
              }}
            >
              {/* {console.log(activeButton, item.id, activeButton == item.id)} */}
              {activeButton == item?.id && subscriptionLoader ? (
                <Spinner size={"sm"} />
              ) : (
                "Subscribe"
              )}
            </Button>
            <CardFooter className="px-0">
              {checkout ? (
                <Paypal
                  price={item?.packagePrice}
                  planID={subscription?.data?.id}
                  setCheckOut={setCheckOut}
                  getPaypalDetail={getPaypalDetail}
                  clientId={liveClientId}
                  // clientId={mode[0].type === "live" ? liveClientId : testClientId}
                />
              ) : (
                ""
              )}
                                  
            </CardFooter>
          </CardBody>
        </Card>
      </div>
    </>
  );
};
