import OnlyHeader from "components/Headers/OnlyHeader";
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import ReactPlayer from "react-player";
import { Card, CardBody } from "reactstrap";
import videoGif from "../assets/img/gif/video.gif";
import { useDispatch, useSelector } from "react-redux";
import { getSubscribedPackages } from "store/actions/packagesAction";
import { getCurrentUserSubscriptions } from "store/actions/subscriptions";

const Tutorials = () => {
  const { subscribedPackages } = useSelector((state) => state.packages);
  const { uid } = useSelector((state) => state.authUser);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getCurrentUserSubscriptions(uid, (response) => {
        dispatch(getSubscribedPackages(response));
      })
    );
  }, []);

  const [playClicked, setPlayClicked] = useState(null);

  const handlePlayClick = (id) => {
    setPlayClicked({ id: id, isPlaying: true });
  };

  const [tutorials, setTutorials] = useState([
    // {
    //   id: 1,
    //   url: "https://firebasestorage.googleapis.com/v0/b/streams-blue.appspot.com/o/RPReplay_Final1677321827.mp4?alt=media&token=e684f8d4-3762-4ca9-af08-dc2f3df3c691",
    // },
    // {
    //   id: 2,
    //   url: "https://firebasestorage.googleapis.com/v0/b/streams-blue.appspot.com/o/RPReplay_Final1680151277.mp4?alt=media&token=b2a50fbf-240f-4096-b221-b8e91f8cf492",
    // },
    // {
    //   id: 3,
    //   url: "https://firebasestorage.googleapis.com/v0/b/streams-blue.appspot.com/o/RPReplay_Final1680396451.mp4?alt=media&token=0771af7e-29ac-49f5-ba46-7c3eab527958",
    // },
    {
      id: 1,
      packageName: "Movies",
      // url: "https://firebasestorage.googleapis.com/v0/b/streams-blue.appspot.com/o/2.mp4?alt=media&token=32c0f49d-4039-4643-a8c9-325e40088fcd",
      // url: "https://firebasestorage.googleapis.com/v0/b/streams-blue.appspot.com/o/movies_new.mp4?alt=media&token=624b980f-c80f-40d3-8aa6-0c773d0ad625",
      url: "https://firebasestorage.googleapis.com/v0/b/streams-blue.appspot.com/o/tvmovies.mp4?alt=media&token=5d51cd69-9ccd-4027-b505-421707dff9f5",
    },
    {
      id: 2,
      packageName: "Sports",
      // url: "https://firebasestorage.googleapis.com/v0/b/streams-blue.appspot.com/o/3%20(1).mp4?alt=media&token=d305ffaa-afd3-44be-b386-4f83ef0efb01",
      // url: "https://firebasestorage.googleapis.com/v0/b/streams-blue.appspot.com/o/sport_new.mp4?alt=media&token=e4f6b479-8d31-4631-a30d-807b2fbc15c4",
      url: "https://firebasestorage.googleapis.com/v0/b/streams-blue.appspot.com/o/sports.mp4?alt=media&token=56084cbf-0cfb-480e-a082-a7032788d134",
    },
    {
      id: 3,
      packageName: "Books",
      // url: "https://firebasestorage.googleapis.com/v0/b/streams-blue.appspot.com/o/1%20(1).mp4?alt=media&token=40cb4e2c-7ced-47d5-bf12-29b3cb212fcf",
      // url: "https://firebasestorage.googleapis.com/v0/b/streams-blue.appspot.com/o/library_new.mp4?alt=media&token=53480d60-2bd5-459f-9405-94b8b3688e4f",
      url: "https://firebasestorage.googleapis.com/v0/b/streams-blue.appspot.com/o/library.mp4?alt=media&token=319e0feb-6258-4088-9e43-68e4b235be6b",
    },
    // {
    //   id: 4,
    //   packageName: "tv shows",
    //   url: "https://firebasestorage.googleapis.com/v0/b/streams-blue.appspot.com/o/IMG_9965.mp4?alt=media&token=9a6bab13-4e27-4635-ac6f-efb52bb289aa",
    // },
    // {
    //   id: 5,
    //   packageName: "tv shows",
    //   url: "https://firebasestorage.googleapis.com/v0/b/streams-blue.appspot.com/o/IMG_9961.mp4?alt=media&token=9a45bae2-7885-4877-a5fa-b4dfd0d90be5",
    // },

    {
      id: 4,
      packageName: "Combined Packages",
      // url: "https://firebasestorage.googleapis.com/v0/b/streams-blue.appspot.com/o/2.mp4?alt=media&token=32c0f49d-4039-4643-a8c9-325e40088fcd",
      url: "https://firebasestorage.googleapis.com/v0/b/streams-blue.appspot.com/o/tvmovies.mp4?alt=media&token=5d51cd69-9ccd-4027-b505-421707dff9f5",
    },
    {
      id: 5,
      packageName: "Combined Packages",
      // url: "https://firebasestorage.googleapis.com/v0/b/streams-blue.appspot.com/o/3%20(1).mp4?alt=media&token=d305ffaa-afd3-44be-b386-4f83ef0efb01",
      url: "https://firebasestorage.googleapis.com/v0/b/streams-blue.appspot.com/o/sports.mp4?alt=media&token=56084cbf-0cfb-480e-a082-a7032788d134",
    },
  ]);

  return (
    <>
      <OnlyHeader />
      <Container className="mt-n5 skills" fluid>
        {subscribedPackages?.length !== 0 ? (
          <Row className="my-3 align-items-center">
            {tutorials
              ?.filter((tutorial) =>
                subscribedPackages.some(
                  (packg) => packg.packageName === tutorial.packageName
                )
              )
              .map((ele) => {
                console.log({ ele });
                const isPlaying =
                  playClicked &&
                  playClicked.id === ele.id &&
                  playClicked.isPlaying;
                return (
                  <Col lg={4} className="mt-2">
                    <Card className="d-flex justify-content-center mt-sm-0">
                      <CardBody className="d-flex justify-content-center cursor-pointer">
                        {!isPlaying && (
                          <img
                            src={videoGif}
                            alt="Loading..."
                            height={360}
                            width={250}
                            onClick={() => handlePlayClick(ele.id)}
                          />
                        )}
                        {isPlaying && (
                          <ReactPlayer
                            url={ele?.url}
                            playing
                            className="player"
                            controls={true}
                          />
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
          </Row>
        ) : (
          <Row className="my-3 align-items-center bg-danger">
            <Card className="w-100">
              <CardBody>No Packages subscribed</CardBody>
            </Card>
          </Row>
        )}
      </Container>
    </>
  );
};

export default Tutorials;
