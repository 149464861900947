import { LOGIN, LOGOUT, UPDATE_USER } from "../types";

const initialState = {
  user: null,
  uid: null,
  allUser: [],
};

export default function authUserReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case LOGIN:
      return {
        ...state,
        user: payload.user,
        uid: payload.uid,
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
        uid: null,
      };

    case "UPDATE_USER":
      return {
        ...state,
        user: {
          ...state.user,
          firstName: payload.firstName,
        },
      };
    case "GET_ALL_USERS":
      return {
        ...state,
        allUser: payload,
      };
    default:
      return { ...state };
  }
}
