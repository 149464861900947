import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Container,
  Row,
  Col,
  Table,
} from "reactstrap";
import Logo from "../../assets/img/brand/PaypalLogo.png";
const TransactionHistory = ({
  modal,
  toggle,
  userTransactions,
  packageName,
}) => {
  const total = userTransactions?.reduce((accumulator, item) => {
    const value =
      parseFloat(item?.amount_with_breakdown?.gross_amount?.value) || 0;
    return accumulator + value;
  }, 0);

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <img src={Logo} height={70} width={130} className="mt-n3" />
      </ModalHeader>
      <ModalBody>
        <div className="d-flex justify-content-center mt-n4 w-100">
          <h1>Transaction History</h1>
        </div>
        <div className=" w-100 mt-3">
          <h2>Package Name : {packageName}</h2>
        </div>
        {/* <Row className="d-flex justify-content-center mt-4"></Row>
        <Col>You payed --------------------------------- $50 on 4-5-2023</Col>
        <Col></Col>
        <Row>
          <Col></Col>
        </Row> */}
        <Table responsive striped className="my-table">
          <thead className="bg-primary text-white">
            <tr>
              <th>
                <h5 className="mb-0 text-white">Description</h5>
              </th>
              <th>
                <h5 className="mb-0 text-white">Date</h5>
              </th>
              <th>
                <h5 className="mb-0 text-white">Amount </h5>
              </th>
            </tr>
          </thead>
          <tbody>
            {userTransactions?.map((item, index) => {
              return (
                <tr>
                  <td>
                    <div className=" d-flex justify-content-start align-items-center">
                      {/* {item?.firstName} */} your account has been credited
                    </div>
                  </td>{" "}
                  <td>
                    <div className="w-100 d-flex justify-content-start align-items-center">
                      {item?.time.split("T")[0].split("-").reverse().join("-")}
                    </div>
                  </td>{" "}
                  <td>
                    <div className="w-100 d-flex justify-content-center align-items-center">
                      ${item?.amount_with_breakdown?.gross_amount?.value}
                    </div>
                  </td>{" "}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div className="d-flex w-100 justify-content-end align-items-center pr-4">
          <h5>Account Creation Fee ------- </h5>
          <h3 className="px-2">$50</h3>
        </div>
        <div className="d-flex w-100 justify-content-end pr-4">
          <h3>Total</h3>
          <h3 className="px-2">${total + 50}</h3>
        </div>
        {/* <Table responsive striped className="my-table">
          <thead className="bg-gray text-white">
            <tr>
              <th>
                <h5 className="mb-0 text-white"></h5>
              </th>
              <th>
                <h5 className="mb-0 text-white float-right">Total</h5>
              </th>
              <th>
                <h5 className="mb-0 text-white float-right">Total </h5>
              </th>
            </tr>
          </thead>
          <tbody></tbody>
        </Table> */}
      </ModalBody>
      <ModalFooter>
        {/* <Button color="primary" onClick={toggle}>
          Save changes
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button> */}
      </ModalFooter>
    </Modal>
  );
};

export default TransactionHistory;
