import firebase from "../../config/firebase";
import { RepositoryFactory } from "repository/RepositoryFactory";
const axios = require("axios");

export const subscriptionLoader = (val) => async (dispatch) => {
  dispatch({ type: "SUBSCRIPTION_LOADER", payload: val });
};
export const subscriptionHistoryLoader = (val) => async (dispatch) => {
  dispatch({ type: "SUBSCRIPTION_HISTORY_LOADER", payload: val });
};
export const cancelSubscriptionLoader = (val) => async (dispatch) => {
  dispatch({ type: "CANCEL_SUBSCRIPTION_LOADER", payload: val });
};
export const getAllUserSubscriptionLoader = (val) => async (dispatch) => {
  dispatch({ type: "USERS_SUBSCRIPTION_LOADER", payload: val });
};

export const addSubscription =
  (totalSpent, uid, obj, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      await firebase
        .firestore()
        .collection("subscriptions")
        .add(obj)
        .then(() => {
          alert("subscription successfully..!");
          firebase
            .firestore()
            .collection("users")
            .doc(uid)
            .update({ totalSpent: totalSpent });
        })
        .catch((ex) => {
          alert(ex.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

export const getAllActiveSubscriptions =
  (onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      await firebase
        .firestore()
        .collection("subscriptions")
        .where("status", "==", "ACTIVE")
        .onSnapshot((querySnapshot) => {
          const activeSubscriptions = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          // console.log(activeSubscriptions, "activeSubscriptions");

          dispatch({
            type: "ALL_ACTIVE_SUBSCRITIONS",
            payload: activeSubscriptions,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

export const getCurrentUserSubscriptions =
  (uid, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch(getAllUserSubscriptionLoader(true));
      await firebase
        .firestore()
        .collection("subscriptions")
        .where("userId", "==", uid)
        .onSnapshot((querySnapshot) => {
          const subscriptions = [];

          querySnapshot.forEach((doc) => {
            subscriptions.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          onSuccess(subscriptions);
          dispatch({
            type: "GET_USER_SUBSCRIPTIONS",
            payload: subscriptions,
          });
          dispatch(getAllUserSubscriptionLoader(false));
        });
    } catch (error) {
      console.log(error);
      dispatch(getAllUserSubscriptionLoader(false));
    }
  };
let subscriptions = RepositoryFactory.get("subscriptions");
export const paypalSubscription =
  (mode, amount, onSuccess = (res) => {}) =>
  async (dispatch) => {
    dispatch(subscriptionLoader(true));
    try {
      await subscriptions
        .tokenGenerate(mode)
        .then(async (responseToken) => {
          var token = responseToken.data.access_token;
          let mode = responseToken.data.mode;
          console.log("token after 1st call ===>", responseToken);
          await subscriptions
            .getProductID(token, mode)
            .then(async (responseProduct) => {
              console.log("response product of current=>>>>>", responseProduct);

              await subscriptions
                .getSubscription(responseProduct.data.id, token, amount, mode)
                .then(async (responsePlan) => {
                  console.log("response plan=>>>>>", responsePlan);

                  dispatch(subscriptionLoader(false));
                  onSuccess(responsePlan);
                })
                .catch((error) => {
                  console.log(error, "Error on Authentication");
                  dispatch(subscriptionLoader(false));
                });
            })
            .catch((error) => {
              console.log(error, "Error on Authentication second call==>");
              dispatch(subscriptionLoader(false));
            });
        })
        .catch((error) => {
          console.log(error, "Error on Authentication");
          dispatch(subscriptionLoader(false));
        });

      // await firebase.firestore().collection("subscriptions").add(obj);
    } catch (error) {
      console.log(error);
      dispatch(subscriptionLoader(false));
    }
  };

export const canncelSubscription =
  (mode,packageId, uid, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(cancelSubscriptionLoader(true));
    // console.log(packageId, "packageId");
    const sub = await firebase
      .firestore()
      .collection("subscriptions")
      .where("packageId", "==", packageId)
      .where("status", "==", "ACTIVE")
      .where("userId", "==", uid)
      .get();

    if (sub.docs.length > 0) {
      try {
        await subscriptions
          .tokenGenerate(mode)
          .then(async (responseToken) => {
            // console.log(
            //   responseToken,
            //   "responseToken",
            //   sub.docs[0].data().subscriptionId,
            //   "subscriptionId"
            // );
            console.log(responseToken?.data.mode, "responseToken?.data.mode");

            await subscriptions
              .canncelSubscription(
                sub.docs[0].data().subscriptionId,
                responseToken?.data.access_token,
                responseToken?.data.mode
              )
              .then(async (res) => {
                // console.log(res, "response from subscriptionId ");
                await firebase
                  .firestore()
                  .collection("subscriptions")
                  .doc(sub.docs[0].id)
                  .update({
                    status: "CANCELLED",
                    updated_at: firebase.firestore.Timestamp.now(),
                  });
                alert("Cancel Subscription Successfully");
                dispatch(cancelSubscriptionLoader(false));
              })
              .catch((error) => {
                console.log(error, "Error on Authentication");
                dispatch(cancelSubscriptionLoader(false));
              });
          })
          .catch((error) => {
            console.log(error, "Error on Authentication");
            dispatch(cancelSubscriptionLoader(false));
          });
      } catch (error) {
        console.log(error);
        dispatch(cancelSubscriptionLoader(false));
      }
    } else {
      console.log("No subscriptions ");
      dispatch(cancelSubscriptionLoader(false));
    }
  };

export const subscriptionHistory =
  (subscriptionId, startDate, endDate, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(subscriptionHistoryLoader(true));

    try {
      await subscriptions
        .tokenGenerate()
        .then(async (responseToken) => {
          // console.log(responseToken, "responseToken");
          await subscriptions
            .getSubscriptionHistory(
              subscriptionId,
              startDate,
              endDate,
              responseToken?.data.access_token,
              responseToken?.data.mode
            )
            .then(async (res) => {
              onSuccess(res);
              dispatch(subscriptionHistoryLoader(false));
            })
            .catch((error) => {
              onSuccess();
              console.log(error, "Error on Authentication");
              dispatch(subscriptionHistoryLoader(false));
            });
        })
        .catch((error) => {
          console.log(error, "Error on Authentication");
          dispatch(subscriptionHistoryLoader(false));
        });
    } catch (error) {
      console.log(error);
      dispatch(subscriptionHistoryLoader(false));
    }
  };

export const getMode = () => async (dispatch) => {
  try {
    const modeRef = await firebase.firestore().collection("mode").get();

    const modeData = modeRef.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    // console.log(modeData[0].type);
    dispatch({
      type: "SET_MODE_DATA",
      payload: modeData,
    });
  } catch (error) {
    console.error(error);
  }
};
