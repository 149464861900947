/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import UpdateProfileModal from "components/modals/UpdateProfileModal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import { LogOut } from "store/actions/authAction";

const AdminNavbar = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state.authUser);

  console.log();
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {props.brandText}
          </Link>

          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    {user?.profilePictureURL ? (
                      <img alt="..." src={user?.profilePictureURL} />
                    ) : (
                      <img
                        alt="..."
                        src={
                          require("../../assets/img/brand/avatar.png").default
                        }
                      />
                    )}
                  </span>

                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {user?.firstName}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>

                <DropdownItem
                  tag={Link}
                  to="/admin/user-profile"
                  onClick={toggleModal}
                >
                  <i className="ni ni-settings-gear-65" />
                  <span>Update Profile Image</span>
                </DropdownItem>

                <UpdateProfileModal
                  showModal={showModal}
                  setShowModal={setShowModal}
                  toggleModal={toggleModal}
                />

                <DropdownItem divider />
                <DropdownItem
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(
                      LogOut(() => {
                        history.push("/auth/login");
                      })
                    );
                  }}
                >
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
