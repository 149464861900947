import OnlyHeader from "components/Headers/OnlyHeader";
import SubscribedPackages from "components/SubscribedPackages";
import { packages } from "helpers/packages";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import { getSubscribedPackages } from "store/actions/packagesAction";
import { getCurrentUserSubscriptions } from "store/actions/subscriptions";
function Subscriptions() {
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  const { subscribedPackages, subscribedPackagesLoader } = useSelector(
    (state) => state.packages
  );
  const { userSubscriptionLoader } = useSelector(
    (state) => state.subscriptions
  );
  const { uid } = useSelector((state) => state.authUser);
  useEffect(() => {
    dispatch(
      getCurrentUserSubscriptions(uid, (response) => {
        dispatch(
          getSubscribedPackages(response, () => {
            setLoader(false);
          })
        );
      })
    );
  }, []);

  return (
    <div>
      <OnlyHeader />
      <Container className="mt--5 skills" fluid>
        <Row className="my-3">
          <Col xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col className="d-flex align-items-center justify-content-between">
                    <div className="d-flex ">
                      <h3>Your Subscriptions</h3>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  {subscribedPackagesLoader || userSubscriptionLoader ? (
                    <div className="d-flex justify-content-center align-items-center w-100 ">
                      <Spinner />
                    </div>
                  ) : subscribedPackages.length !== 0 ? (
                    subscribedPackages
                      // ?.filter((data) => data.btnText == "Subscribed")
                      ?.map((item, index) => {
                        return (
                          <>
                            <Col lg={3} md={6} sm={12}>
                              <SubscribedPackages item={item} index={index} />
                            </Col>
                          </>
                        );
                      })
                  ) : (
                    <Col>
                      <div className="d-flex justify-content-center align-items-center">
                        <h3>You have not yet subscribed to any packages.</h3>
                      </div>
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Subscriptions;
