import { LOGIN, LOGIN_LOADER, LOGOUT, REGISTER_LOADER } from "../types";

const initialState = {
  registerLoading: false,
  loginLoading: false,
  resetLoading: false,
  nameLoading: false,
  newPasswordLoading: false,
  updateProfile: false,
};

export default function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case "REGISTER_LOADER":
      return {
        ...state,
        registerLoading: payload,
      };
    case "LOGIN_LOADER":
      return {
        ...state,
        loginLoading: payload,
      };
    case "UPDATE_PROFILE": {
      return {
        ...state,
        updateProfile: payload,
      };
    }
    case "RESET_LOADER":
      return {
        ...state,
        resetLoading: payload,
      };
    case "NAME_LOADER":
      return {
        ...state,
        nameLoading: payload,
      };
    case "PASSWORD_LOADER":
      return {
        ...state,
        newPasswordLoading: payload,
      };

    default:
      return {
        ...state,
      };
  }
}
