import axios from "axios";

const initialState = {
  currentUserSubscriptions: [],
  activeSubscriptions: [],
  subscriptionLoader: false,
  cancelSubscriptionLoader: false,
  userSubscriptionLoader: false,
  historyLoader: false,
  mode: [],
};

export default function subscriptionsReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case "GET_USER_SUBSCRIPTIONS":
      return {
        ...state,
        currentUserSubscriptions: payload,
      };
    case "SUBSCRIPTION_LOADER":
      return {
        ...state,
        subscriptionLoader: payload,
      };
    case "CANCEL_SUBSCRIPTION_LOADER":
      return {
        ...state,
        cancelSubscriptionLoader: payload,
      };
    case "USERS_SUBSCRIPTION_LOADER":
      return {
        ...state,
        userSubscriptionLoader: payload,
      };
    case "ALL_ACTIVE_SUBSCRITIONS":
      return {
        ...state,
        activeSubscriptions: payload,
      };
    case "SUBSCRIPTION_HISTORY_LOADER":
      return {
        ...state,
        historyLoader: payload,
      };
    case "SET_MODE_DATA":
      return {
        ...state,
        mode: payload,
      };
    default:
      return { ...state };
  }
}
