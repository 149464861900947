/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import { LogOut } from "store/actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import logo2 from "../../assets/img/brand/logo.png";
import UpdateProfileModal from "components/modals/UpdateProfileModal";

var ps;

const Sidebar = (props) => {
  const dispatch = useDispatch();

  const [collapseOpen, setCollapseOpen] = useState();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  // creates the links that appear in the left menu / Sidebar
  const { user, uid } = useSelector((state) => state.authUser);
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.isMenu !== false) {
        switch (prop.name) {
          case "Manage Packages":
          case "Site":
          case "Users":
            if (user?.role !== "admin") {
              return null;
            }
            break;
          case "Settings":
          case "Packages":
          case "My Subscriptions":
          case "My Tutorials":
            if (user?.role === "admin") {
              return null;
            }
            break;
          default:
            break;
        }

        // if (prop.name === "Manage Packages" && user?.role !== "admin") {
        //   return null;
        // }
        // if (prop.name === "Site" && user?.role !== "admin") {
        //   return null;
        // }
        // if (prop.name === "Users" && user?.role !== "admin") {
        //   return null;
        // }
        // if (prop.name === "My Tutorials" && user?.role !== "admin") {
        //   return null;
        // }
        // if (prop.name === "Settings" && user?.role === "admin") {
        //   return null;
        // }
        // if (prop.name === "Packages" && user?.role === "admin") {
        //   return null;
        // }
        // if (prop.name === "My Subscriptions" && user?.role === "admin") {
        //   return null;
        // }
        return (
          <NavItem key={key}>
            <NavLink
              to={prop.layout + prop.path}
              tag={NavLinkRRD}
              onClick={closeCollapse}
              activeClassName="active"
            >
              <i className={prop.icon} />
              {prop.name}
            </NavLink>
          </NavItem>
        );
      }
    });
  };
  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}

        <NavbarBrand className="pt-0 " {...navbarBrandProps}>
          <div className="d-flex justify-content-center d-none d-md-flex">
            <img
              src={logo2}
              style={{ maxWidth: "50%" }}
              alt="Logo"
              className="d-none d-md-flex"
            />
          </div>
          <b>STREAMS BLUE</b>
        </NavbarBrand>

        {/* User */}
        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            {/* <DropdownToggle nav className="nav-link-icon">
              <i className="ni ni-bell-55" />
            </DropdownToggle> */}
            <DropdownMenu
              aria-labelledby="navbar-default_dropdown_1"
              className="dropdown-menu-arrow"
              right
            >
              <DropdownItem>Action</DropdownItem>
              <DropdownItem>Another action</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Something else here</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  {user?.profilePictureURL ? (
                    <img alt="..." src={user?.profilePictureURL} />
                  ) : (
                    <img
                      alt="..."
                      src={require("../../assets/img/brand/avatar.png").default}
                    />
                  )}
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Welcome!</h6>
              </DropdownItem>

              <DropdownItem
                tag={Link}
                to="/admin/user-profile"
                onClick={toggleModal}
              >
                <i className="ni ni-settings-gear-65" />
                <span>Update Profile Image</span>
              </DropdownItem>

              <UpdateProfileModal
                showModal={showModal}
                toggleModal={toggleModal}
                setShowModal={setShowModal}
              />

              <DropdownItem divider />
              <DropdownItem
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(
                    LogOut()
                    //   () => {
                    //   // console.log("first")
                    //   history.push("/auth/login");
                    // }
                  );
                }}
              >
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none ">
            <Row className="d-flex justify-content-between align-items-center">
              <Col className="collapse-brand text-nowrap mt-2" xs="6">
                <span className="font-weight-bold">STREAMS BLUE</span>
              </Col>

              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Form */}
          {/* <Form className="mt-4 mb-3 d-md-none">
            <InputGroup className="input-group-rounded input-group-merge">
              <Input
                aria-label="Search"
                className="form-control-rounded form-control-prepended"
                placeholder="Search"
                type="search"
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <span className="fa fa-search" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Form> */}
          {/* Navigation */}
          <Nav navbar>{createLinks(routes)}</Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
