import OnlyHeader from "components/Headers/OnlyHeader";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { handlePasswordChangeAction } from "store/actions/authAction";
import { handleNameUpdate } from "store/actions/authAction";

const Settings = () => {
  const { user, uid } = useSelector((state) => state.authUser);
  const { nameLoading, newPasswordLoading } = useSelector(
    (state) => state.auth
  );
  const [updatedName, setUpdatedName] = useState("");
  const dispatch = useDispatch();
  const handleNameSubmit = (e) => {
    e.preventDefault();
    dispatch(
      handleNameUpdate(uid, updatedName, () => {
        alert("Name updated Successfully");
      })
    );
  };

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "oldPassword") {
      setOldPassword(value);
    } else if (name === "newPassword") {
      setNewPassword(value);
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  const [disable, setDisable] = useState(false);
  const handlePasswordUpdate = (e) => {
    e.preventDefault();
    if (newPassword == confirmPassword) {
      dispatch(
        handlePasswordChangeAction(
          uid,
          oldPassword,
          newPassword,
          confirmPassword,
          () => {
            alert("Password Updated Successfully");
            setConfirmPassword("");
            setOldPassword("");
            setNewPassword("");
          },
          (res) => {
            alert(res);
            setConfirmPassword("");
            setOldPassword("");
            setNewPassword("");
          }
        )
      );
    } else {
      alert("New password and confirm password do not match.");
    }
  };
  return (
    <>
      <OnlyHeader />
      <Container className="mt--5 skills" fluid>
        <Row className="my-3">
          <Col xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col className="d-flex align-items-center justify-content-between">
                    <div className="d-flex ">
                      <h3>Profile Settings</h3>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={8} className="m-auto">
                    <Form onSubmit={handleNameSubmit}>
                      <FormGroup>
                        <Label>Update Name</Label>
                        <InputGroup className="my-2">
                          <Input
                            placeholder="Update Name"
                            type="text"
                            defaultValue={user?.firstName}
                            onChange={(e) => setUpdatedName(e.target.value)}
                            required
                          />
                        </InputGroup>
                        <div className="d-flex justify-content-end">
                          <Button
                            className="my-2 "
                            size="sm"
                            color="primary"
                            disabled={
                              !updatedName || updatedName === user?.firstName
                            }
                          >
                            {nameLoading ? (
                              <Spinner size="sm" />
                            ) : (
                              "Update Name"
                            )}
                          </Button>
                        </div>
                      </FormGroup>
                    </Form>
                  </Col>
                  <Col md={8} className="m-auto">
                    <Form onSubmit={handlePasswordUpdate}>
                      <FormGroup>
                        <Label>Old Password</Label>
                        <InputGroup className="my-2">
                          <Input
                            placeholder="Old Password"
                            type="password"
                            name="oldPassword"
                            value={oldPassword}
                            onChange={handleChange}
                            required
                          />
                        </InputGroup>
                        <Label>New Password</Label>
                        <InputGroup className="my-2">
                          <Input
                            placeholder="New Password"
                            type="password"
                            name="newPassword"
                            value={newPassword}
                            onChange={handleChange}
                            required
                          />
                        </InputGroup>
                        <Label>Confirm Password</Label>
                        <InputGroup className="my-2">
                          <Input
                            placeholder="Confirm Password"
                            type="password"
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={handleChange}
                            required
                          />
                        </InputGroup>
                        <div className="d-flex justify-content-end">
                          <Button
                            className="my-2"
                            size="sm"
                            color="primary"
                            type="submit"
                            disabled={newPassword !== confirmPassword}
                          >
                            {newPasswordLoading ? (
                              <Spinner size="sm" />
                            ) : (
                              "Update Password"
                            )}
                          </Button>
                        </div>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Settings;
